import gsap from 'gsap';
import { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { PageContext } from '../../contexts/PageContext';
import SunIcon from '../../assets/sun.svg';
import MoonIcon from '../../assets/moon.svg';
import { useTheme } from '../../contexts/ThemeContext';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
  color: ${(props) => props.theme.appearance.textColor};
  top: 80px;
  right: 60px;
  z-index: 3;
  opacity: 0;
`;

const Item = styled.p`
  font-family: fira-mono;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Logo = () => {
  const { page, changePage } = useContext(PageContext);
  const { themeMode, toggleTheme } = useTheme();

  const handleClick = (index: number) => {
    changePage(index);
  };
  const WrapperRef = useRef(null);

  useEffect(() => {
    if (page === 0) {
      gsap.to(WrapperRef.current, {
        opacity: 1,
        duration: 1,
      });
    } else {
      gsap.to(WrapperRef.current, {
        opacity: 0,
        duration: 1,
      });
    }
  }, [page]);

  useEffect(() => {
    const tl = gsap
      .timeline({
        paused: true,
        defaults: { duration: 3 },
      })
      .to(WrapperRef.current, { opacity: 1 });
    tl.restart();
    // let tween = gsap.to(WrapperRef, { opacity: 1, y: 100, duration: 1 });
    // tween.play();
  }, []);

  return (
    <Wrapper ref={WrapperRef}>
      <Item
        onClick={() => {
          handleClick(1);
        }}
      >
        ABOUT
      </Item>
      <Item
        onClick={() => {
          handleClick(6);
        }}
      >
        VISION
      </Item>
      <Item
        onClick={() => {
          handleClick(7);
        }}
      >
        TEAM
      </Item>
      <Item
        onClick={() => {
          handleClick(8);
        }}
      >
        PROJECT
      </Item>
      <Item
        onClick={() => {
          handleClick(9);
        }}
      >
        CONTACT
      </Item>
      <Item
        onClick={() => {
          toggleTheme();
        }}
      >
        {themeMode === 'light' && <img src={SunIcon} alt={'Change theme'} />}
        {themeMode === 'dark' && <img src={MoonIcon} alt={'Change theme'} />}
      </Item>
    </Wrapper>
  );
};

export default Logo;
