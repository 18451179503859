import { ReactNode, useEffect, useState } from 'react';
import { PageWrapper } from './PageWrapper';
import styles from './styles.module.scss';

interface PageScrollerProps {
  animationDuration: number;
  animationBuffer: number;
  page: number;
  setPage: Function;
  children: ReactNode | ReactNode[];
}

const threshold = 50;

export const PageScroller = ({
  animationDuration,
  animationBuffer,
  page,
  setPage,
  children,
}: PageScrollerProps) => {
  const [num, setNum] = useState(0);
  const [blockScroll, setBlockScroll] = useState(false);
  const [blockScrollUp, setBlockScrollUp] = useState(true);
  const [blockScrollDown, setBlockScrollDown] = useState(true);
  const [touchStartPos, setTouchStartPos] = useState(0);

  useEffect(() => {
    if (Array.isArray(children)) {
      setNum(children.length);
    } else {
      setNum(1);
    }
  }, [children]);

  useEffect(() => {
    if (blockScroll) {
      const timeout = setTimeout(() => {
        setBlockScroll(false);
        clearTimeout(timeout);
      }, animationBuffer);
    }
  }, [animationBuffer, animationDuration, blockScroll]);

  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      if (
        e.deltaY > threshold &&
        page < num - 1 &&
        !blockScrollDown &&
        !blockScroll
      ) {
        setPage((prev: number) => prev + 1);
        setBlockScroll(true);
        setBlockScrollDown(true);
      } else if (
        e.deltaY < -threshold &&
        page > 0 &&
        !blockScrollUp &&
        !blockScroll
      ) {
        setPage((prev: number) => prev - 1);
        setBlockScroll(true);
        setBlockScrollUp(true);
      }
    };

    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [blockScroll, blockScrollUp, blockScrollDown, num, page, setPage]);

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      setTouchStartPos(e.touches[0].clientY);
    };

    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, [touchStartPos]);

  useEffect(() => {
    const handleMobileScroll = (e: TouchEvent) => {
      if (
        touchStartPos - e.changedTouches[0].clientY > threshold &&
        page < num - 1 &&
        !blockScrollDown &&
        !blockScroll
      ) {
        setPage((prev: number) => prev + 1);
        setBlockScroll(true);
        setBlockScrollDown(true);
      } else if (
        touchStartPos - e.changedTouches[0].clientY < -threshold &&
        page > 0 &&
        !blockScrollUp &&
        !blockScroll
      ) {
        setPage((prev: number) => prev - 1);
        setBlockScroll(true);
        setBlockScrollUp(true);
      }
    };

    window.addEventListener('touchend', handleMobileScroll);

    return () => {
      window.removeEventListener('touchend', handleMobileScroll);
    };
  }, [
    blockScroll,
    blockScrollUp,
    blockScrollDown,
    num,
    page,
    setPage,
    touchStartPos,
  ]);

  useEffect(() => {
    const handleScrollZero = () => {
      if (page < num)
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };

    window.addEventListener('scroll', handleScrollZero);
    window.addEventListener('wheel', handleScrollZero);

    return () => {
      window.removeEventListener('scroll', handleScrollZero);
      window.removeEventListener('wheel', handleScrollZero);
    };
  }, [page, num]);

  return (
    <div className={styles.page_scroller}>
      {Array.isArray(children) ? (
        children.map((child, index) => {
          return (
            <PageWrapper
              key={index}
              isFirst={index === 0}
              isFront={index === page}
              display={page >= index}
              duration={animationDuration}
              setBlockScrollDown={setBlockScrollDown}
              setBlockScrollUp={setBlockScrollUp}
              zIndex={index}
            >
              {child}
            </PageWrapper>
          );
        })
      ) : (
        <PageWrapper
          isFirst={true}
          isFront={true}
          display={true}
          duration={animationDuration}
          setBlockScrollDown={setBlockScrollDown}
          setBlockScrollUp={setBlockScrollUp}
        >
          {children}
        </PageWrapper>
      )}
    </div>
  );
};
