import styled from 'styled-components';
import { PageContext } from '../../contexts/PageContext';
import { useContext } from 'react';
import { ReactComponent as HyerLogo } from '../../assets/hyer_logo.svg';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${(props) => props.theme.appearance.textColor};
  margin-top: 89px;
  margin-left: 62px;
  font-family: paralucent;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 3px;
  z-index: 3;
`;

const Logo = () => {
  const { page } = useContext(PageContext);
  return page === 0 ? (
    <Wrapper>
      {/* <p>POLAR</p> */}
      <HyerLogo />
    </Wrapper>
  ) : null;
};

export default Logo;
