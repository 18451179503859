import { motion } from 'framer-motion';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import styles from './styles.module.scss';

interface PageWrapperProps {
  display: boolean;
  isFirst: boolean;
  isFront: boolean;
  duration: number;
  setBlockScrollUp: Function;
  setBlockScrollDown: Function;
  children: ReactNode;
  zIndex?: number;
}

export const PageWrapper = ({
  display,
  isFirst,
  isFront,
  duration,
  setBlockScrollUp,
  setBlockScrollDown,
  children,
  zIndex,
}: PageWrapperProps) => {
  const { themeMode } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const variants = {
    visible: {
      y: 0,
      transition: {
        duration: duration / 1000,
        ease: 'easeOut',
      },
    },
    hidden: {
      y: '100vh',
      transition: {
        duration: duration / 1000,
        ease: 'easeOut',
      },
    },
  };

  const handleScroll = useCallback(() => {
    if (ref.current && isFront) {
      if (ref.current.scrollTop > 0) {
        setBlockScrollUp(true);
      } else {
        setBlockScrollUp(false);
      }

      if (
        ref.current.scrollTop + ref.current.clientHeight + 1 <
        ref.current.scrollHeight
      ) {
        setBlockScrollDown(true);
      } else {
        setBlockScrollDown(false);
      }
    }
  }, [setBlockScrollDown, setBlockScrollUp, isFront, innerHeight]);

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  useEffect(() => {
    const setVh = () => {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
      setInnerHeight(window.innerHeight);
    };
    setVh();

    window.addEventListener('resize', setVh);

    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  return (
    <motion.div
      className={`${styles.page_wrapper} ${
        themeMode === 'light' ? styles.light : styles.dark
      }`}
      ref={ref}
      variants={variants}
      initial={isFirst ? 'visible' : 'hidden'}
      animate={display ? 'visible' : 'hidden'}
      onScroll={handleScroll}
      style={{ zIndex: zIndex }}
    >
      {children}
    </motion.div>
  );
};
