import gsap from 'gsap';
import styled from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import useIsInViewport from '../../hooks/useIsInViewport';
import { useTheme } from '../../contexts/ThemeContext';

export const MenuTitle = styled.p`
  font-family: fira-mono;
  font-size: 16px;
  opacity: 0.4;
  margin: 120px 0 120px 0;
  text-align: center;
`;

interface HeadTilteProps {
  size: number;
  children: React.ReactNode;
}

const HeadTitleComponent = styled.p<{ size: number }>`
  text-align: center;
  font-family: paralucent;
  font-size: ${(props) => props.size}px;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 40px;
  }
  font-weight: 500;
  z-index: 100;

  svg {
    width: 100%;
    height: auto;

    @media ${({ theme }) => theme.device.mobile} {
      width: 150px;
    }
  }
`;

const HeadTitle = ({ size, children }: HeadTilteProps) => {
  const Ref = useRef(null);
  const isInViewport = useIsInViewport(Ref);
  useEffect(() => {
    gsap.fromTo(Ref.current, { opacity: 0 }, { opacity: 1, duration: 2 });
  }, [isInViewport]);
  return (
    <HeadTitleComponent size={size} ref={Ref}>
      {children}
    </HeadTitleComponent>
  );
};
HeadTitle.defaultProps = {
  size: 200,
};
export { HeadTitle };

interface DescriptionContainerSetProps {
  descript: string;
  pageStartPosition: number;
  posY: number;
}

export const DescriptionContainerSet = ({
  descript,
  pageStartPosition,
  posY,
}: DescriptionContainerSetProps) => {
  const Ref = useRef<HTMLDivElement>(null);
  const [dsecriptionTopPosition, setDsecriptionTopPosition] =
    useState<number>(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const separateDescription = (description: string, windowSizeX: number) => {
    const descriptions: string[] = [];
    const words = description.split(' ');
    let maxLenDescription = windowSizeX * 0.6 * (41 / 283);

    let newDescription: string = '';
    words.forEach((word: string) => {
      if (newDescription.length + word.length <= maxLenDescription) {
        if (newDescription.length === 0) {
          newDescription = word;
        } else {
          newDescription = newDescription + ' ' + word;
        }
      } else {
        descriptions.push(newDescription);
        newDescription = word;
      }
    });
    if (newDescription.length > 0) {
      descriptions.push(newDescription);
    }

    return descriptions;
  };

  const [descriptions, setDescriptions] = useState<string[]>(
    separateDescription(descript, window.innerWidth)
  );

  const handleResize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    setDescriptions(separateDescription(descript, window.innerWidth));
  }, [descript]);

  useEffect(() => {
    setDsecriptionTopPosition(Ref.current!.getBoundingClientRect().top);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div style={{ marginTop: '30px', textAlign: 'center' }} ref={Ref}>
      {descriptions.map((description, index) => {
        return (
          <DescriptionContainer
            key={`${description}`}
            descript={description}
            pageStartPosition={pageStartPosition}
            posY={posY}
            dsecriptionTopPosition={dsecriptionTopPosition}
            index={index}
            lenDescription={descriptions.length}
            windowSize={windowSize}
          />
        );
      })}
    </div>
  );
};

interface DescriptionProps {
  descript: string;
  pageStartPosition: number;
  posY: number;
  dsecriptionTopPosition: number;
  index: number;
  lenDescription: number;
  windowSize: { width: number; height: number };
}
const DescriptionContainer = ({
  descript,
  pageStartPosition,
  posY,
  dsecriptionTopPosition,
  index,
  lenDescription,
  windowSize,
}: DescriptionProps) => {
  const getDescriptionShadowPercent = () => {
    const correctionDescriptionPosition =
      dsecriptionTopPosition - pageStartPosition;
    const startPoint = (windowSize.height * index) / lenDescription;
    const standardShadowPercent = windowSize.height / lenDescription;
    const aleadyScrolledDistance =
      windowSize.height - (correctionDescriptionPosition - posY);
    const shadowPercent =
      aleadyScrolledDistance - (startPoint / standardShadowPercent) * 100;
    if (shadowPercent < 0) {
      return 0;
    } else {
      return Math.min(
        aleadyScrolledDistance - (startPoint / standardShadowPercent) * 100,
        100
      );
    }
  };

  return (
    <DescriptionContainerDiv>
      <BaseDescription>{descript}</BaseDescription>
      <Description>{descript}</Description>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'left',
        }}
      >
        <CoveredDescription widthPercent={getDescriptionShadowPercent()}>
          {descript}
        </CoveredDescription>
      </div>
    </DescriptionContainerDiv>
  );
};
const DescriptionContainerDiv = styled.div`
  position: relative;
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const BaseDescription = styled.p`
  font-family: paralucent;
  font-weight: 500;
  font-size: 14px;
  height: 22.4px;
  text-align: center;
  color: transparent;
  margin-left: 20%;
  margin-right: 20%;
  }
`;
const Description = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: paralucent;
  font-weight: 500;
  font-size: 14px;
  line-height: 22.4px;
  opacity: 0.2;
  width: 100%;
`;
const CoveredDescription = styled.p<{ widthPercent: number }>`
  z-index: 1;
  width: ${(props) => props.widthPercent}%;
  height: 22.4px;
  font-family: paralucent;
  font-weight: 500;
  font-size: 14px;
  line-height: 22.4px;
  overflow: hidden;
  white-space: nowrap;
  }
`;

interface DescriptionContainerProps {
  children: string;
}

export const HighlightedDescriptionContainer = ({
  children,
}: DescriptionContainerProps) => {
  const { themeMode } = useTheme();
  return (
    <div style={{ position: 'relative', marginTop: '20px', overflow: 'auto' }}>
      <HighlightedDescription>{children}</HighlightedDescription>
      <FilledBackground color={themeMode} />
      <HighlightedDescription
        style={{
          position: 'absolute',
          color: themeMode === 'light' ? '#ffffff' : '#000000',
          top: '50%',
          transform: 'translate(0,-50%)',
        }}
      >
        {children}
      </HighlightedDescription>
    </div>
  );
};

const FilledBackground = (props: any) => {
  const Ref = useRef(null);
  const isInViewport = useIsInViewport(Ref);
  useEffect(() => {
    gsap.fromTo(Ref.current, { width: '0%' }, { width: '100%' });
  }, [isInViewport]);
  return <FilledBackgroundComponent ref={Ref} color={props.color} />;
};

const FilledBackgroundComponent = styled.p`
  position: absolute;
  height: 100%;
  background: ${(props) => (props.color === 'light' ? '#000000' : '#ffffff')};
  top: 50%;
  transform: translate(0, -50%);
`;

const HighlightedDescription = styled.div`
  font-family: paralucent;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  padding: 5px 10px 5px 10px;
`;
